import React from "react";
import { Trans, withTranslation } from 'react-i18next';
import Container from '@mui/material/Container';

import './layout.css';

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="bg1">
                <Container maxWidth="md">
                    <div className="homeDiv">
                        <img className="homePortrait" src="/img/portrait.jpg" />
                        <div className="homeText">
                            <div className="homeTitle"><Trans>Wenbing Ma</Trans></div>
                            <Trans i18nKey="obituary">
                                <p>On February 19, 2023, after a long battle with cancer, Wenbing Ma, 59, passed away at the Teresa Dellar Palliative Care Residence. She leaves behind her loving husband, her son and daughter-in-law, her two sisters and her brother.</p>
                                <p>Wenbing is born in Beijing, China in October 1963. After graduating as an engineer from Beijing Forestry University in 1986, she worked for 8 years developing fiberboard and designing parts. She met her husband in 1989 and had a son 3 years later. In 1994, she moved to Montreal, Canada, following her husband. After graduating from Dawson's Department of Mechanical Engineering Technology, she worked as a Mechanical Designer from 1999 until present.</p>
                                <p>Wenbing loves traveling, flowers, hiking, classical music, dancing. She made great contributions to the Montreal traditional Qi<span>go</span>ng community, where she was in charge of flowers and organized activities for children.</p>
                                <p>A celebration of life will take place at Belvedere Cemetery and Funeral Complex, on Sunday, March 26th, 2023 at 2 PM. Please refer to the Event page for more information.</p>
                            </Trans>
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

export default withTranslation()(Index);
