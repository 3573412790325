import React from "react";
import axios from "../components/axios";
import { Trans, withTranslation } from 'react-i18next';
import Container from '@mui/material/Container';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CircularProgress from '@mui/material/CircularProgress';
import CardActions from '@mui/material/CardActions';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Masonry from '@mui/lab/Masonry';

import './layout.css';

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            items: [],
            columns: 3,
            upload: false,
            uploading: false,
            uploadProgress: 0,
            openImage: false,
            openIndex: 0,
            status: 'view',
            saving: false,
            edit_id: '',
            delete_id: '',
            delete_preview: ''
        };

        this.onResize = this.onResize.bind(this);
    }

    componentDidMount() {
        this.onResize();
        window.addEventListener('resize', this.onResize);
        this.getItems();


    }

    async getItems() {
        this.setState({ loading: true })
        let resp = await axios.get('/photos');
        this.setState({ items: resp.data, loading: false });
    }

    onResize() {
        if (window.screen.availWidth >= 760) {
            this.setState({ columns: 3 });
        } else if (window.screen.availWidth >= 420) {
            this.setState({ columns: 2 });
        } else {
            this.setState({ columns: 1 });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    openUpload() {
        this.setState({ status: 'add' });
        document.getElementById('description').value = '';
        document.getElementById('title').value = '';

        window.setTimeout(function () {
            window.scrollTo({ top: document.body.scrollHeight, left: 0, behavior: "smooth" });
        }, 50)
    }

    uploadPhoto() {
        if (document.getElementById('file').files.length == 0) return;

        let d = {
            file: document.getElementById('file').files[0],
            title: document.getElementById('title').value,
            description: document.getElementById('description').value,
            name: document.getElementById('name').value
        };
        localStorage.setItem('name', d.name);

        console.log(d);
        this.setState({ uploading: true });
        axios.postForm('/photos', d, {
            onUploadProgress: function (e) {
                console.log('onUploadProgress', e);
                this.setState({ uploading: true, uploadProgress: (e.loaded / e.total * 100) });
            }.bind(this)
        }).then(function (response) {
            console.log('postForm', response);
            this.setState({ uploading: false, items: response.data });
            document.getElementById('file').value = null;
            document.getElementById('title').value = '';
            document.getElementById('description').value = '';
        }.bind(this));
    }

    openImage(index, e) {
        e.stopPropagation();
        console.log('openImage', index);
        this.setState({ openIndex: index, openImage: true });
    }

    openDelete(item) {
        this.setState({ delete_id: item._id, delete_preview: process.env.REACT_APP_API_URL + '/static/' + item.img });
    }
    deleteItem() {
        console.log('delete', this.state.delete_id);

        this.setState({ saving: true });
        axios.delete('/photos/' + this.state.delete_id)
            .then(function (response) {
                console.log(response);
                this.setState({ delete_id: '', saving: false });
                this.getItems();
            }.bind(this))
            .catch(function (error) {
                console.log(error);
            }.bind(this));
    }

    openEdit(i) {
        this.setState({ edit_id: i._id, status: 'edit' });
        document.getElementById('name').value = i.name;
        document.getElementById('description').value = i.description;
        document.getElementById('title').value = i.title;
        document.getElementById('filename').value = i.filename;

        window.setTimeout(function () {
            window.scrollTo({ top: document.body.scrollHeight, left: 0, behavior: "smooth" });
        }, 50)
    }
    editItem() {
        let d = {
            title: document.getElementById('title').value,
            description: document.getElementById('description').value,
            name: document.getElementById('name').value,
            _id: this.state.edit_id
        };
        localStorage.setItem('name', d.name);

        console.log(d);
        this.setState({ saving: true });
        axios.put('/photos/' + d._id, d)
            .then(function (response) {
                console.log(response);
                this.setState({ saving: false, status: 'view' });
                this.getItems();
                document.getElementById('description').value = '';
                document.getElementById('title').value = '';
            }.bind(this))
            .catch(function (error) {
                console.log(error);
            }.bind(this));

    }

    render() {
        return (
            <>
                <div className="bgp">
                    <div className="bgpTitle"><Trans>PHOTOS</Trans>
                        <button className="addBtn" onClick={this.openUpload.bind(this)}>
                            <AddIcon fontSize="inherit" /> <Trans>Upload</Trans>
                        </button>
                    </div>

                </div>
                <div className="photosDiv">
                    <Container maxWidth="md" >
                        <div style={{ height: '8px' }}></div>
                        <CircularProgress className="loadingProgress" hidden={!this.state.loading} />
                        <Masonry columns={this.state.columns} spacing={1}>
                            {this.state.items.map((item, index) => (
                                <div key={item.img}>
                                    <img className="imgElement"
                                        src={process.env.REACT_APP_API_URL + '/static/' + item.img}
                                        alt={item.title}
                                        title={item.title}
                                        loading="lazy"
                                        onClick={this.openImage.bind(this, index)}
                                    />
                                    <CardActions hidden={!item.edit} className="imageActions">
                                        <Button size="small" onClick={this.openEdit.bind(this, item)}>Edit</Button>
                                        <Button size="small" onClick={this.openDelete.bind(this, item)}>Delete</Button>
                                    </CardActions>
                                </div>
                            ))}
                        </Masonry>
                        <div id="upload">
                            <button className="addBtn addBtnLight" onClick={this.openUpload.bind(this)} hidden={this.state.status == 'add'}>
                                <AddIcon fontSize="inherit" /> <Trans>Upload</Trans>
                            </button>
                            <div className="formDiv" hidden={this.state.status == 'view'} >
                                <TextField label={<Trans>Photo *</Trans>} id="file" inputProps={{ "accept": "image/*" }} className="form50" autoFocus variant="outlined" size="small" type="file" InputLabelProps={{ shrink: true }} margin="dense" hidden={this.state.status == 'edit'} />
                                <TextField label={<Trans>Photo</Trans>} id="filename" className="form50" variant="outlined" size="small" InputLabelProps={{ shrink: true }} margin="dense" hidden={this.state.status == 'add'} disabled />
                                <TextField label={<Trans>Title</Trans>} id="title" className="form50" variant="outlined" size="small" InputLabelProps={{ shrink: true }} margin="dense" />
                                <TextField label={<Trans>Description</Trans>} id="description" variant="outlined" size="small" InputLabelProps={{ shrink: true }} multiline fullWidth margin="dense"
                                    helperText={<Trans>You'll be able to edit or delete your photo after uploading</Trans>} />
                                <TextField label={<Trans>Name</Trans>} id="name" className="form50" variant="outlined" size="small" InputLabelProps={{ shrink: true }} margin="dense" defaultValue={localStorage.getItem('name')} />
                                <div className="form50" style={{ paddingTop: '5px' }} hidden={this.state.status == 'edit'}>
                                    <Button variant="contained" onClick={this.uploadPhoto.bind(this)} hidden={this.state.uploading}>
                                        <Trans>Upload Photo</Trans> 
                                    </Button>
                                    <Button variant="contained" disabled hidden={!this.state.uploading}>
                                        <Trans>Uploading</Trans> {this.state.uploadProgress.toFixed(0)}%
                                    </Button>
                                </div>
                                <div className="form50" style={{ paddingTop: '5px' }} hidden={this.state.status == 'add'}>
                                    <Button variant="contained" onClick={this.editItem.bind(this)} disabled={this.state.saving}>
                                        <Trans>Update</Trans>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Container>
                    <Dialog
                        open={this.state.delete_id != ''}
                        onClose={() => { this.setState({ delete_id: '' }) }}
                    >
                        <DialogContent>
                            <DialogContentText>
                                <Trans>Delete this photo?</Trans><br />
                                <img src={this.state.delete_preview} className="deletePhotoPreview" />
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => { this.setState({ delete_id: '' }) }} size='small'>
                                <Trans>Cancel</Trans>
                            </Button>
                            <Button onClick={this.deleteItem.bind(this)} size='small' disabled={this.state.saving}>
                                <Trans>Delete</Trans>
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                {this.state.items.length > this.state.openIndex && (
                    <Backdrop open={this.state.openImage} className="ImgBackdrop" onClick={() => { this.setState({ openImage: false }) }}>
                        <div className="fullScreenImg"
                            style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}/static/${this.state.items[this.state.openIndex].img})` }}>
                            <IconButton className="ImgClose" >
                                <CloseIcon />
                            </IconButton>
                            <div className="ImgNav">
                                <IconButton hidden={this.state.openIndex === 0}
                                    onClick={this.openImage.bind(this, this.state.openIndex - 1)}>
                                    <ArrowBackIosNewIcon />
                                </IconButton>
                                <div hidden={this.state.openIndex > 0}></div>
                                <IconButton hidden={this.state.openIndex === this.state.items.length - 1}
                                    onClick={this.openImage.bind(this, this.state.openIndex + 1)}>
                                    <ArrowForwardIosIcon />
                                </IconButton>
                            </div>
                            <div className="imgInfoDiv">
                                <div className="imgTitle">{this.state.items[this.state.openIndex].title}</div>
                                <div className="imgDesc">{this.state.items[this.state.openIndex].description}</div>
                            </div>
                        </div>
                    </Backdrop>
                )}

            </>
        );
    }
}

export default withTranslation()(Index);
