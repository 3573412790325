import React from "react";
import { Trans, withTranslation } from 'react-i18next';
import { Outlet, Link } from "react-router-dom";
import Container from '@mui/material/Container';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';

import './layout.css';

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            archor: null,
            open: false,
        };
    }

    componentDidMount() {
    }

    handleOpen(e) {
        //console.log(e.currentTarget);
        this.setState({ open: true });
    }

    handleClose(c) {
        this.setState({ open: c });
    }

    render() {
        return (
            <>
                <header className="header">
                    <Container maxWidth="md">
                        <div className="headerBar">
                            <div className="headerTitle"><Link to={`/`}><Trans>Wenbing Ma</Trans></Link></div>
                            <div className="headerSpacer"></div>
                            <div className="headerLink" hidden><Link to={`/timeline`}>Timeline</Link></div>
                            <div className="headerLink"><Link to={`/event`}><Trans>Event</Trans></Link></div>
                            <div className="headerLink"><Link to={`/memories`}><Trans>Memories</Trans></Link></div>
                            <div className="headerLink"><Link to={`/photos`}><Trans>Photos</Trans></Link></div>
                            <div className="headerLang" onClick={this.props.i18n.changeLanguage.bind(this, 'en')}><Link>EN</Link></div>
                            <div className="headerLang zh" onClick={this.props.i18n.changeLanguage.bind(this, 'zh')}><Link>中文</Link></div>
                            <IconButton onClick={this.handleOpen.bind(this)} className="headerBtn"
                                ref={(input) => { this.archor = input; }} >
                                <MenuOutlinedIcon />
                            </IconButton>
                            <Menu className="menuDiv"
                                anchorEl={this.archor}
                                open={this.state.open}
                                onClose={this.handleClose.bind(this, false)}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <Link to={`/`}><MenuItem onClick={this.handleClose.bind(this, false)}><Trans>Obituary</Trans></MenuItem></Link>
                                <Link to={`/event`}><MenuItem onClick={this.handleClose.bind(this, false)}><Trans>Event</Trans></MenuItem></Link>
                                <Link to={`/memories`}><MenuItem onClick={this.handleClose.bind(this, false)}><Trans>Memories</Trans></MenuItem></Link>
                                <Link to={`/photos`}><MenuItem onClick={this.handleClose.bind(this, false)}><Trans>Photos</Trans></MenuItem></Link>
                            </Menu>
                        </div>
                    </Container>
                </header>
                <Outlet />
            </>
        );
    }
}

export default withTranslation()(Index);
