import React from "react";
import { Trans, withTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

import './App.css';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    this.onResize();
    window.addEventListener('resize', this.onResize)
  }

  onResize() {
    console.log('resize', window.screen.availWidth);
    let e = document.getElementById('cardContainer');

    if (window.screen.availWidth < 1400) {
      let w = 1400;
      if (window.screen.availWidth <= 900) w = 900;
      e.style.transform = 'scale(calc(' + window.screen.availWidth + '/' + w + '))';
    } else {
      e.style.transform = '';
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <div id='cardContainer'>
            <div className='portrait'></div>
            <div className='inviteText'>
              <div className='langText' hidden>
                <a href='#' onClick={this.props.i18n.changeLanguage.bind(this, 'en')}>EN</a>
                <span className="langSeparator">|</span>
                <a href='#' onClick={this.props.i18n.changeLanguage.bind(this, 'zh')} className='langTextZh'>中文</a>
              </div>
              <div className='t1'><Trans>IN LOVING MEMORY OF</Trans></div>
              <div className='t2'><Trans i18nKey="title">Wenbing Ma</Trans></div>
              <div className='t4'><Trans>October, 1963 - February, 2023</Trans></div>
              <div className='t5'><Trans i18nKey="p1">We will gather to share memories, stories, <br className="no-desktop" />
                to rejoice and remember Wenbing <br className="no-desktop" />
                who will be greatly missed.</Trans></div>
              <div className='t3'><a href='/memorial.ics'><Trans>SUNDAY, MARCH 26, 2023</Trans></a><br />
                <div className='t3sub'>
                  <Trans>Visitation and Condolences: 2PM - 3PM EST</Trans><br />
                  <Trans>Memorial Service: 3PM - 4PM EST</Trans>
                </div>
                <div style={{height: '24px'}}></div>
              </div>

              <div className='t3'>
                <a href="https://goo.gl/maps/uiwnf2f5sshsUa828" target="_blank" rel="noreferrer">
                  BELVEDERE CEMETERY AND FUNERAL COMPLEX</a><br />
                <a href="https://goo.gl/maps/uiwnf2f5sshsUa828" target="_blank" rel="noreferrer">
                  <div className='t3sub'>22025 Trans-Canada Highway<span className="no-mobile">, </span><br className="no-desktop" />
                    Senneville, QC H9X 0B2</div></a>
              </div>
              <div className='t5'>
                <b><Link to={`/`}>wenbingma.com</Link></b></div>

            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default withTranslation()(Index);
