import React from "react";
import { Trans, withTranslation } from 'react-i18next';
import Container from '@mui/material/Container';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Masonry from '@mui/lab/Masonry';
import CircularProgress from '@mui/material/CircularProgress';
import CardActions from '@mui/material/CardActions';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import axios from "../components/axios";
import './layout.css';

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            items: [],
            add: false,
            edit: false,
            edit_id: '',
            delete_id: '',
            delete_preview: '',
            adding: false,
            columns: 2
        };
        this.onResize = this.onResize.bind(this);
    }

    componentDidMount() {
        this.onResize();
        window.addEventListener('resize', this.onResize);
        this.getItems();
    }
    onResize() {
        if (window.screen.availWidth >= 600) {
            this.setState({ columns: 2 });
        } else {
            this.setState({ columns: 1 });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    async getItems() {
        this.setState({ loading: true })
        let resp = await axios.get('/memories');
        this.setState({ items: resp.data, loading: false });
    }

    openAdd() {
        this.setState({ add: true, edit: false });
        window.setTimeout(function () {
            window.scrollTo({ top: document.body.scrollHeight, left: 0, behavior: "smooth" });
        }, 50)
    }

    addItem() {
        let d = {
            name: document.getElementById('name').value,
            relationship: document.getElementById('relationship').value,
            description: document.getElementById('description').value
        }
        if (d.description == '') return;

        localStorage.setItem('name', d.name);

        this.setState({ adding: true });
        axios.post('/memories', d)
            .then(function (response) {
                console.log(response);
                this.setState({ adding: false, items: response.data });
                document.getElementById('description').value = '';
            }.bind(this))
            .catch(function (error) {
                console.log(error);
            }.bind(this));
    }

    saveItem() {
        let d = {
            name: document.getElementById('name').value,
            relationship: document.getElementById('relationship').value,
            description: document.getElementById('description').value,
            _id: this.state.edit_id
        }
        if (d.description == '') return;

        localStorage.setItem('name', d.name);

        //console.log(d); return;

        this.setState({ adding: true });
        axios.put('/memories/' + d._id, d)
            .then(function (response) {
                console.log(response);
                this.setState({ adding: false, edit: false, add: false });
                this.getItems();
                document.getElementById('description').value = '';
            }.bind(this))
            .catch(function (error) {
                console.log(error);
            }.bind(this));
    }

    editItem(i) {
        console.log('edit', i)
        this.setState({ edit: true, add: false, edit_id: i._id });
        document.getElementById('name').value = i.name;
        document.getElementById('description').value = i.description;
        document.getElementById('relationship').value = i.relationship;

        window.setTimeout(function () {
            window.scrollTo({ top: document.body.scrollHeight, left: 0, behavior: "smooth" });
        }, 50)
    }
    askDelete(item) {
        this.setState({ delete_id: item._id, delete_preview: item.description.slice(0, 15) + "..." })
    }
    deleteItem() {
        console.log('delete', this.state.delete_id);

        this.setState({ adding: true });
        axios.delete('/memories/' + this.state.delete_id)
            .then(function (response) {
                console.log(response);
                this.setState({ delete_id: '', adding: false });
                this.getItems();
            }.bind(this))
            .catch(function (error) {
                console.log(error);
            }.bind(this));
    }

    stringToColor(string) {
        let hash = 0;
        let i;
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
        let color = '#';
        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        return color;
    }
    genAvatar(name) {
        let p = name.split(' ');
        let init = p[0][0];
        if (p.length > 1) init += p[1][0];

        return {
            sx: {
                bgcolor: this.stringToColor(name),
            },
            children: init,
        };
    }

    formatTime(s) {
        let date = new Date(Date.parse(s));
        return date.toLocaleDateString(undefined, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
    }

    render() {
        return (
            <>
                <div className="bgp">
                    <div className="bgpTitle">
                        <Trans>MEMORIES & CONDOLENCES</Trans>
                        <button className="addBtn" onClick={this.openAdd.bind(this)}>
                            <AddIcon fontSize="inherit" /> <Trans>Post</Trans>
                        </button>
                    </div>
                </div>
                <div className="photosDiv">
                    <Container maxWidth="md" >
                        <div style={{ height: '48px' }}></div>
                        <CircularProgress className="loadingProgress" hidden={!this.state.loading} />
                        <Masonry columns={this.state.columns} spacing={1}>
                            {this.state.items.map((item, index) => (
                                <Card variant="outlined" className="memoryCard" key={item._id}>
                                    <CardHeader
                                        avatar={<Avatar {...this.genAvatar(item.name)} />}
                                        title={item.name}
                                        subheader={this.formatTime(item.created)}
                                    />
                                    <CardContent>
                                        <Typography variant="body2" color="text.secondary">
                                            {item.description}
                                        </Typography>
                                    </CardContent>
                                    <CardActions hidden={!item.edit}>
                                        <Button size="small" onClick={this.editItem.bind(this, item)}><Trans>Edit</Trans></Button>
                                        <Button size="small" onClick={this.askDelete.bind(this, item)}><Trans>Delete</Trans></Button>
                                    </CardActions>
                                </Card>
                            ))}
                        </Masonry>
                        <div id="upload">
                            <button className="addBtn addBtnLight" onClick={this.openAdd.bind(this)} hidden={this.state.add}>
                                <AddIcon fontSize="inherit" /> <Trans>Post</Trans>
                            </button>
                            <div className="formDiv" hidden={!this.state.add && !this.state.edit} >
                                <TextField label={<Trans>Name</Trans>} id="name" className="form50" variant="outlined" size="small" InputLabelProps={{ shrink: true }} margin="dense" defaultValue={localStorage.getItem('name')} />
                                <TextField label={<Trans>Relationship</Trans>} id="relationship" className="form50" variant="outlined" size="small" InputLabelProps={{ shrink: true }} margin="dense" inputProps={{ list: 'relations' }} />
                                <TextField label={<Trans>Your condolences *</Trans>} id="description" variant="outlined" size="small" InputLabelProps={{ shrink: true }} multiline fullWidth margin="dense" minRows={2}
                                    helperText={<Trans>You'll be able to edit or delete your message after posting</Trans>} />
                                <div className="form50" style={{ paddingTop: '5px' }}>
                                    <Button variant="contained" onClick={this.addItem.bind(this)} disabled={this.state.adding} hidden={this.state.edit}>
                                        <Trans>Post</Trans>
                                    </Button>
                                    <Button variant="contained" onClick={this.saveItem.bind(this)} disabled={this.state.adding} hidden={this.state.add}>
                                        <Trans>Update</Trans>
                                    </Button>
                                </div>
                            </div>
                            <datalist id="relations">
                                <option><Trans>Friend</Trans></option>
                                <option><Trans>Classmate</Trans></option>
                                <option><Trans>Colleague</Trans></option>
                                <option><Trans>Family</Trans></option>
                                <option><Trans>Family friend</Trans></option>
                            </datalist>
                        </div>
                    </Container>
                    <Dialog
                        open={this.state.delete_id != ''}
                        onClose={() => { this.setState({ delete_id: '' }) }}
                    >
                        <DialogContent>
                            <DialogContentText>
                                <Trans>Delete</Trans> "{this.state.delete_preview}"?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => { this.setState({ delete_id: '' }) }} size='small'>
                                <Trans>Cancel</Trans>
                            </Button>
                            <Button onClick={this.deleteItem.bind(this)} size='small' disabled={this.state.adding}>
                                <Trans>Delete</Trans>
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </>
        );
    }
}

export default withTranslation()(Index);
